var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", [_vm._v(_vm._s(_vm.ladder.text))]),
      _vm._l(_vm.ladder.controls, function (control, index) {
        return _c(
          "span",
          { key: index },
          [
            _c("label", [_vm._v(_vm._s(control.label))]),
            control.controlType === "input"
              ? _c("el-input", {
                  staticClass: "renderInput",
                  attrs: {
                    placeholder: control.description,
                    min: 0,
                    disabled: !_vm.notView,
                  },
                  on: { change: _vm.inputChange },
                  model: {
                    value: control.value,
                    callback: function ($$v) {
                      _vm.$set(control, "value", $$v)
                    },
                    expression: "control.value",
                  },
                })
              : _vm._e(),
            control.controlType === "select"
              ? _c(
                  "el-select",
                  {
                    staticClass: "renderSelect",
                    attrs: {
                      placeholder: control.description,
                      disabled: !_vm.notView,
                    },
                    on: { change: _vm.selectChange },
                    model: {
                      value: control.value,
                      callback: function ($$v) {
                        _vm.$set(control, "value", $$v)
                      },
                      expression: "control.value",
                    },
                  },
                  _vm._l(control.selectOptions, function (item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: { label: item.value, value: item.key },
                    })
                  }),
                  1
                )
              : _vm._e(),
            control.controlType === "labelPostfix"
              ? _c("span", [_vm._v(_vm._s(control.value))])
              : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }