<!--
 * @Author: yzr
 * @Date: 2020-12-04 15:58:15
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-30 11:31:00
-->
<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '../../../../../../../components/form';
import DemoRender from '../demo_render';

formCreate.component('DemoRender', DemoRender);

let timer = null;

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      ruleCode: '',
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  async mounted() {
    await this.getFormRule('dms_pro_rule_add_rule_form');

    // 初始化数据
    this.intData();
    if (this.formConfig.type === 'limit') this.hiddenFields(true, ['promotionType']);
    if (this.formConfig.type !== 'calculate') this.hiddenFields(true, ['remarks']);
    if (this.formConfig.type !== 'limit') this.hiddenFields(true, ['levelNo']);

    this.rule.map((v) => {
      let rowData = v;
      if (rowData.field === 'promotionType') {
        rowData.props = {
          disabled: true,
        };
        rowData.value = this.formConfig.promotionType;
      }
      if (rowData.field === 'demo') {
        rowData = {
          ...v,
          props: {
            showDemo: false,
            controlRow: {
              text: '',
              controls: [],
            },
            ruleType: '',
          },
        };
      }
      if (rowData.field === 'testParam') {
        rowData.props = {
          ...rowData.props,
          rows: '10',
        };
        rowData.on = {
          blur: this.ChangeParam,
        };
      }
      return rowData;
    });

    this.reload(this.rule);
  },
  methods: {
    // 提交表单
    submit() {
      const formData = this.getFormData();
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        if (formData) this.submitForm();
      }, 500);
    },

    submitForm() {
      const params = {
        ...this.getFormData(),
        testParam: JSON.parse(this.getFormData().testParam),
        ruleType: this.formConfig.type,
      };

      if (this.formConfig.id) {
        params.id = this.formConfig.id;
      }
      request.post('/dms/promotion/rule', params).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.$emit('onClose');
          // this.$emit('onGetList');
          this.formConfig.updateList();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 初始化页面
    async intData() {
      if (!this.formConfig.id) {
        this.resetFields();
      } else {
        request.get('/dms/promotion/rule/editinfo', { id: this.formConfig.id }).then((res) => {
          this.ruleCode = res.result.ruleCode;
          this.setValue({
            ...res.result,
            testParam: JSON.stringify(res.result.testParam),
          });
          this.getRule('demo').props = {
            showDemo: true,
            controlRow: res.result.testParam.controlRow,
            ruleType: this.formConfig.type,
            notView: this.formConfig.notViews,
          };
          this.getRule('demo').on = {
            testFunc: this.testFunc,
          };
          this.reload();
        });
      }
    },

    // 调试参数改变
    ChangeParam() {
      if (this.getFormData().testParam) {
        this.getRule('demo').props = {
          showDemo: true,
          controlRow: JSON.parse(this.getFormData().testParam).controlRow,
          ruleType: this.formConfig.type,
        };
      }

      this.getRule('demo').on = {
        testFunc: this.testFunc,
      };
    },

    // 调试
    testFunc(ladder) {
      const params = {
        ...this.getFormData(),
        ruleType: this.formConfig.type,
        testParam: JSON.parse(this.getFormData().testParam),
      };

      if (ladder.text) params.testParam.controlRow = ladder;

      const formData = this.getFormData();
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        if (formData) {
          request.post('/dms/promotion/rule/testfunc', params).then((res) => {
            // if (res.code === 200) this.$message.success(res.message);
            // else this.$message.warning(res.message);
            this.setValue({
              result: JSON.stringify(res.result),
            });
          });
        }
      }, 500);
    },
  },
};
</script>
