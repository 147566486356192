<!--
 * @Author: yzr
 * @Date: 2020-11-24 14:24:20
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-30 11:31:14
-->

<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import RuleList from '../components/rule_list';

let timer = null;
formCreate.component('RuleList', RuleList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      // form内组件
      rule: [],
      temInfo: {},
      promotionType: '',
      requestUrl: '/dms/promotion/template/editinfo',
      checkedInfo: {
        calculate: [],
        condition: [],
        limited: [],
      },
    };
  },

  async mounted() {
    const formRule = await this.getFormRule('dms-pro_rule-form');
    this.rule = formRule;
    // 初始化表单
    this.initEditValue();
    const newRule = formRule.map((item) => {
      let obj = item;
      // 计算规则
      if (item.field === 'calculate') {
        obj = {
          ...item,
          props: {
            type: 'calculate',
            list: [],
            checked: [],
            promotionType: this.promotionType,
            notView: this.formConfig.code !== 'view',
          },
          on: {
            updateList: this.updateList,
            ruleChecked: this.ruleChecked,
          },
          value: {},
        };
      }
      // 条件规则
      if (item.field === 'condition') {
        obj = {
          ...item,
          props: {
            type: 'condition',
            list: [],
            checked: [],
            promotionType: this.promotionType,
            notView: this.formConfig.code !== 'view',
          },
          on: {
            updateList: this.updateList,
            ruleChecked: this.ruleChecked,
          },
          value: {},
        };
      }
      // 限制规则
      if (item.field === 'limited') {
        obj = {
          ...item,
          props: {
            type: 'limited',
            list: [],
            checked: [],
            promotionType: this.promotionType,
            notView: this.formConfig.code !== 'view',
          },
          on: {
            updateList: this.updateList,
            ruleChecked: this.ruleChecked,
          },
          value: {},
        };
      }
      if (item.field === 'templateCode') {
        obj = {
          ...item,
          validate: [
            ...item.validate,
            {
              trigger: 'blur',
              pattern: '^[a-zA-Z0-9]*$',
              message: '仅能输入英文和数字的组合',
            },
          ],
        };
      }
      return obj;
    });

    this.rule = newRule;
    this.getRule('promotionType').on.change = this.changeRuleType;
    this.reload();
  },

  methods: {
    // 提交表单
    submit() {
      const formData = this.getFormData();
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        if (formData) this.submitForm();
      }, 500);
    },

    // 创建请求
    submitForm() {
      const { edit, id } = this.formConfig;
      console.log('id', id);
      const requestUrl = '/dms/promotion/template';

      let params = {
        ...this.getFormData(),
        calculate: [],
        condition: [],
        limited: [],
      };
      if (edit) {
        params.id = id;
      }

      Object.keys(this.checkedInfo).map((key) => {
        if (!this.checkedInfo[key].length) {
          this.temInfo[key].map((tem) => {
            if (tem.selected) this.checkedInfo[key].push(tem);
            return tem;
          });
        }
        return key;
      });
      params = {
        ...params,
        ...this.checkedInfo,
      };

      request.post(requestUrl, params).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.$emit('onClose');
          this.$emit('onGetList');
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 编辑赋值
    initEditValue() {
      const { edit, id } = this.formConfig;
      if (!edit) {
        this.resetFields();
      } else {
        const params = {
          templateId: id,
        };
        request.get(this.requestUrl, params).then((res) => {
          const calChecked = [];
          const conChecked = [];
          const limChecked = [];
          const { result } = res;
          this.temInfo = result;
          this.promotionType = result.promotionType;

          // 计算条件
          result.calculate.map((item) => {
            if (item.selected) {
              calChecked.push(item.ruleName);
            }
            return item;
          });
          this.getRule('calculate').props.list = result.calculate;
          this.getRule('calculate').props.checked = calChecked;
          this.getRule('calculate').props.promotionType = result.promotionType;

          // 满足条件
          result.condition.map((item) => {
            if (item.selected) {
              conChecked.push(item.ruleName);
            }
            return item;
          });
          this.getRule('condition').props.list = result.condition;
          this.getRule('condition').props.checked = conChecked;
          this.getRule('condition').props.promotionType = result.promotionType;

          // 限制条件
          result.limited.map((item) => {
            if (item.selected) {
              limChecked.push(item.ruleName);
            }
            return item;
          });
          this.getRule('limited').props.list = result.limited;
          this.getRule('limited').props.checked = limChecked;
          this.getRule('limited').props.promotionType = result.promotionType;

          this.setValue({
            ...result,
            dictCode: Number(result.dictCode),
          });
        });
      }
    },

    // 更新规则数据
    updateList(type) {
      const params = {
        templateId: this.formConfig.id,
        promotionType: this.promotionType,
      };
      request.get(this.requestUrl, params).then((res) => {
        console.log(res, 'res');

        const { result } = res;
        this.temInfo = result;
        this.getRule('calculate').props.list = result.calculate;
        this.getRule('condition').props.list = result.condition;
        this.getRule('limited').props.list = result.limited;
        // switch (type) {
        //   case 'calculate':
        //     this.getRule('calculate').props.list = result.calculate;
        //     break;
        //   case 'condition':
        //     this.getRule('condition').props.list = result.condition;
        //     break;
        //   case 'limit':
        //     this.getRule('limit').props.list = result.limited;
        //     break;
        //   default:
        //     break;
        // }
      });
    },

    // 规则选择
    ruleChecked(val) {
      switch (val.type) {
        case 'calculate':
          this.checkedInfo.calculate = val.list;
          break;
        case 'condition':
          this.checkedInfo.condition = val.list;
          break;
        case 'limited':
          this.checkedInfo.limited = val.list;
          break;
        default:
          break;
      }
    },
    changeRuleType(val) {
      this.promotionType = val;
      this.getRule('calculate').props.promotionType = val;
      this.getRule('condition').props.promotionType = val;
      this.getRule('limit').props.promotionType = val;
      this.updateList();
    },
  },
};
</script>
