<!--
 * @Author: yzr
 * @Date: 2020-11-24 10:02:48
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-30 11:31:38
-->
<script>
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
// import configs from './data';
import Form from '../form';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
  },
  data() {
    return {
      // requestUrl: '/dms/m/rotationpic/list',
      // configs,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  async mounted() {
    const columns = await this.getConfigList('dms_promotion_rule');
  },
  methods: {
    setColumn(col) {
      const rowData = col;

      if (rowData.field === 'createDate') {
        rowData.formatter = ({ row }) => `${row.createDate} ${row.createDateSecond}`;
      }

      if (rowData.field === 'updateDate') {
        rowData.formatter = ({ row }) => `${row.updateDate ? row.updateDate : ''} ${row.updateDateSecond ? row.updateDateSecond : ''}`;
      }
      return rowData;
    },
    modalClick({ val, row }) {
      this.formConfig = {
        edit: false,
      };
      if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig = {
          ...val,
          ...row,
          edit: true,
        };
        this.openFull();
      } else if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.openFull();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig = {
          ...val,
          ...row,
          edit: true,
        };
        this.openFull();
      }
    },
  },
};
</script>
