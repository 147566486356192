<!--
 * @Author: yzr
 * @Date: 2020-12-03 10:32:09
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-30 11:16:57
-->
<template>
  <div>
    <!-- <span v-if="type === 'condition'">示例：</span> -->
    <span>{{ ladder.text }}</span>
    <span v-for="(control, index) in ladder.controls" :key="index">
      <label>{{ control.label }}</label>
      <el-input @change="inputChange" class='renderInput' v-if="control.controlType === 'input'" v-model="control.value" :placeholder="control.description" :min='0' :disabled="!notView"></el-input>
      <el-select @change="selectChange" class='renderSelect' v-if="control.controlType === 'select'" v-model="control.value" :placeholder="control.description" :disabled="!notView">
        <el-option
          v-for="item in control.selectOptions"
          :key="item.key"
          :label="item.value"
          :value="item.key">
        </el-option>
      </el-select>
      <span v-if="control.controlType === 'labelPostfix'">{{control.value}}</span>
    </span>
  </div>
</template>

<script>
'RuleRender';

export default {
  name: 'RuleRender',
  props: {
    ladder: Object,
    type: String,
    notView: Boolean,
  },
  watch: {
    ladder(nv) {
      console.log(this.type, nv);
    },
  },
  methods: {
    inputChange() {
      this.$parent.saveLadder(this.ladder);
    },

    selectChange() {
      this.$parent.saveLadder(this.ladder);
    },
  },
};
</script>

<style scoped>
.renderItem {
  margin: 5px 0;
}
.renderInput {
  width: 125px;
  margin: 0 5px;
  display: inline-block;
}
.renderSelect {
  width: 150px;
  margin: 0 5px;
  display: inline-block;
}
</style>
