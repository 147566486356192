<!--
 * @Author: yzr
 * @Date: 2020-12-02 18:18:35
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-30 11:30:49
-->
<template>
  <div>
    <el-checkbox-group v-model="checkList" @change="checkedChange">
      <div v-for="(item, index) in list" :key="index">
        <div class="tlt">
          <el-checkbox :label="item.ruleName" :disabled="!notView"></el-checkbox>
          <el-button-group>
            <el-button plain @click="openModal(item)" v-if="notView">编辑</el-button>
            <el-popconfirm @confirm="delRule(item.id)" title="确认删除该规则？">
              <el-button plain slot="reference" v-if="notView">删除</el-button>
            </el-popconfirm>
          </el-button-group>
        </div>
        <div v-if="type !== 'calculate'" class="flex">
          <span>示例：</span>
          <RuleRender
            :ladder="item.controlRow"
            :type="item.ruleType"
            :notView="notView"
          />
        </div>
        <div v-else>说明：{{ item.remarks ? item.remarks : "暂无说明" }}</div>
      </div>
      <el-button
        v-if="type === 'condition'"
        class="plus"
        icon="el-icon-plus"
        @click="openModal"
        :disabled="!notView"
        >新增满足条件
      </el-button>
      <el-button
        v-else-if="type === 'calculate'"
        class="plus"
        icon="el-icon-plus"
        @click="openModal"
        :disabled="!notView"
        >新增计算规则
      </el-button>
      <el-button
        v-else-if="type === 'limited'"
        class="plus"
        icon="el-icon-plus"
        @click="openModal"
        :disabled="!notView"
        >新增限量规则
      </el-button>
    </el-checkbox-group>

    <!-- 弹框 -->
    <Modal @onClose="closeModal" @submit="onSubmit" :modalConfig="modalConfig" ref="modalForm">
      <RenderModal @onClose="closeModal" :formConfig="formConfig" />
    </Modal>
  </div>
</template>

<script>
import request from '@/found/utils/request';
import RenderModal from '../rule_modal';
import RuleRender from '../rule_render';

import Modal from '../../../../../../../components/modal';

export default {
  name: 'RuleList',
  components: {
    RuleRender,
    RenderModal,
    Modal,
  },
  props: {
    list: Array,
    type: String,
    checked: Array,
    promotionType: String,
    notView: Boolean,
  },
  watch: {
    list(nv) {
      console.log(nv);

      const newList = nv;
      newList.map((item) => {
        const data = item;
        data.controlRow = JSON.parse(data.testParam).controlRow;
        return data;
      });
      this.list = newList;
    },
    checked(nv) {
      this.checkList = nv;
    },
  },
  data() {
    return {
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      }, // 弹框信息
      formConfig: {}, // 弹框数据
      formName: 'RenderModal',
      controlRow: {},
      checkList: this.checked,
      dialogVisible: true,
    };
  },
  mounted() {
  },
  methods: {
    checkedChange(val) {
      const checkedList = [];
      this.list.map((item) => {
        val.map((v) => {
          if (item.ruleName === v) {
            checkedList.push({ id: item.id });
          }
          return v;
        });
        return item;
      });
      const param = {
        type: this.type,
        list: checkedList,
      };
      this.$emit('ruleChecked', param);
    },
    delRule(id) {
      request.post(`/dms/promotion/rule/del/${id}`).then((res) => {
        if (res.code === 200) {
          this.$emit('updateList', this.type);
          this.$message.success('操作成功！');
        }
      });
    },
    // 打开弹窗
    openModal(item) {
      console.log(item, this.promotionType);

      this.formConfig = {
        ...item,
        type: this.type,
        promotionType: this.promotionType,
        updateList: () => this.$emit('updateList', this.type),
        notViews: this.notView,
      };
      this.modalConfig = {
        ...{ visible: true, type: 'Modal' },
      };
      if (item.id) this.modalConfig.title = '编辑';
      else this.modalConfig.title = '新增';
    },
    // 打开抽屉
    openDrawer() {
      this.modalConfig = {
        ...{ visible: true, type: 'Drawer' },
      };
    },
    // 全屏打开
    openFull() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Full' },
      };
    },
    onSubmit(val) {
      console.log(val);
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
  },
};
</script>

<style scoped>
.tlt {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #80808052;
  margin-bottom: 5px;
}
.tlt .el-button {
  border: 0;
}
.plus {
  margin-top: 5px;
  width: 100%;
}
.flex {
  display: flex;
}
</style>
