var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-checkbox-group",
        {
          on: { change: _vm.checkedChange },
          model: {
            value: _vm.checkList,
            callback: function ($$v) {
              _vm.checkList = $$v
            },
            expression: "checkList",
          },
        },
        [
          _vm._l(_vm.list, function (item, index) {
            return _c("div", { key: index }, [
              _c(
                "div",
                { staticClass: "tlt" },
                [
                  _c("el-checkbox", {
                    attrs: { label: item.ruleName, disabled: !_vm.notView },
                  }),
                  _c(
                    "el-button-group",
                    [
                      _vm.notView
                        ? _c(
                            "el-button",
                            {
                              attrs: { plain: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.openModal(item)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          )
                        : _vm._e(),
                      _c(
                        "el-popconfirm",
                        {
                          attrs: { title: "确认删除该规则？" },
                          on: {
                            confirm: function ($event) {
                              return _vm.delRule(item.id)
                            },
                          },
                        },
                        [
                          _vm.notView
                            ? _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", plain: "" },
                                  slot: "reference",
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.type !== "calculate"
                ? _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("span", [_vm._v("示例：")]),
                      _c("RuleRender", {
                        attrs: {
                          ladder: item.controlRow,
                          type: item.ruleType,
                          notView: _vm.notView,
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", [
                    _vm._v(
                      "说明：" +
                        _vm._s(item.remarks ? item.remarks : "暂无说明")
                    ),
                  ]),
            ])
          }),
          _vm.type === "condition"
            ? _c(
                "el-button",
                {
                  staticClass: "plus",
                  attrs: { icon: "el-icon-plus", disabled: !_vm.notView },
                  on: { click: _vm.openModal },
                },
                [_vm._v("新增满足条件 ")]
              )
            : _vm.type === "calculate"
            ? _c(
                "el-button",
                {
                  staticClass: "plus",
                  attrs: { icon: "el-icon-plus", disabled: !_vm.notView },
                  on: { click: _vm.openModal },
                },
                [_vm._v("新增计算规则 ")]
              )
            : _vm.type === "limited"
            ? _c(
                "el-button",
                {
                  staticClass: "plus",
                  attrs: { icon: "el-icon-plus", disabled: !_vm.notView },
                  on: { click: _vm.openModal },
                },
                [_vm._v("新增限量规则 ")]
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "Modal",
        {
          ref: "modalForm",
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.closeModal, submit: _vm.onSubmit },
        },
        [
          _c("RenderModal", {
            attrs: { formConfig: _vm.formConfig },
            on: { onClose: _vm.closeModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }