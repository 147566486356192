<!--
 * @Author: yzr
 * @Date: 2020-12-02 16:01:31
 * @LastEditors: yzr
 * @LastEditTime: 2020-12-02 16:02:28
-->
<template>
  <div class="home">
    <TablePage />
  </div>
</template>

<script>
import TablePage from './table';

export default {
  name: 'ProRule',
  components: {
    TablePage,
  },
};
</script>
