var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDemo
    ? _c(
        "div",
        [
          _c("RuleRender", {
            attrs: {
              ladder: _vm.controlRow,
              type: _vm.ruleType,
              notView: _vm.notView,
            },
          }),
          _c(
            "div",
            { staticClass: "testFunc" },
            [_c("el-button", { on: { click: _vm.test } }, [_vm._v("调试")])],
            1
          ),
        ],
        1
      )
    : _c("div")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }