<!--
 * @Author: yzr
 * @Date: 2020-12-07 10:58:43
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-30 11:30:01
-->
<template>
  <div v-if="showDemo">
    <RuleRender
      :ladder="controlRow"
      :type="ruleType"
      :notView="notView"
    />
    <div class="testFunc">
      <el-button @click="test">调试</el-button>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import RuleRender from '../rule_render';

export default {
  components: {
    RuleRender,
  },
  props: {
    showDemo: Boolean,
    controlRow: Object,
    ruleType: String,
    notView: Boolean,
  },
  watch: {
    // controlRow(nv) {
    //   console.log('controlRow', nv);
    // },
    // showDemo(nv) {
    //   console.log(nv, this);
    // },
  },
  data() {
    return {
      ladder: {},
    };
  },
  methods: {
    test() {
      this.$emit('testFunc', this.ladder);
    },

    saveLadder(val) {
      this.ladder = val;
    },
  },
};
</script>

<style scoped>
.testFunc {
  display: flex;
  justify-content: flex-end;
}
</style>
